import React from 'react'
import { graphql } from 'gatsby'
import { Typography } from '@material-ui/core'

import Seo from '~/src/components/seo'
import Page from '~/src/components/page'

export default function MdPage({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Page>
      <Seo
        site={data.site}
      />
      <div
        style={{
          margin: 'auto',
          width: '100%',
          maxWidth: '1000px',
          padding: '1rem',
        }}
      >
        <Typography variant="h3">
          {frontmatter.title}
        </Typography>
        <Typography variant="h6">
          last updated: {frontmatter.date}
        </Typography>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Page>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
