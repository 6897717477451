import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

const SEO = ({
  site,
  title,
  lang,
  meta,
  description,
  ogTitle,
  ogDescription,
  ogImage,
  canonical,
}) => {
  const metaDescription = description || site.siteMetadata.description
  const openGraphTitle = ogTitle || site.siteMetadata.ogTitle
  const openGraphDescription = ogDescription || metaDescription
  return (
    <Helmet
      htmlAttributes={{
        lang: lang || 'id',
      }}
      title={site.siteMetadata.title}
      titleTemplate={title ? `%s | ${title}` : ''}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: openGraphTitle,
        },
        {
          property: 'og:description',
          content: openGraphDescription,
        },
        {
          name: 'og:image',
          content: ogImage,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        // {
        //   name: 'twitter:card',
        //   content: 'summary',
        // },
        // {
        //   name: 'twitter:creator',
        //   content: site.siteMetadata.author,
        // },
        // {
        //   name: 'twitter:title',
        //   content: title,
        // },
        // {
        //   name: 'twitter:description',
        //   content: metaDescription,
        // },
      ].concat(meta)}
    >
      {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  )
}

SEO.defaultProps = {
  title: '',
  lang: 'en',
  meta: [],
  description: '',
  ogTitle: '',
  ogDescription: '',
  ogImage: 'https://majin.land/images/majin.sq.png',
}

SEO.propTypes = {
  site: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogImage: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
